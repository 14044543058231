<template>
  <div v-if="params.value?.test_page" class="status-cells">
    <Popper class="status-popper" hover arrow :content="(params.value?.status_value || params.value)">
      <div class="">
        <div>
          <div class="status-icon" v-if="(params.value?.status_value || params.value) === 'Тест не выдан'">
            <img src="@/assets/images/icons/clock1.svg" alt="Статус:Тест не выдан"/>
          </div>
          <div class="status-icon" v-else-if="(params.value?.status_value || params.value) === 'Тест не пройден'">
            <img src="@/assets/images/icons/clock2.svg" alt="Статус:Тест не пройден"/>
          </div>
          <div class="status-icon"
               v-else-if="(params.value?.status_value || params.value) === 'В процессе прохождения'">
            <img src="@/assets/images/icons/clock3.svg" alt="Статус:В процессе прохождения"/>
          </div>
          <div class="status-icon" v-else-if="(params.value?.status_value || params.value) === 'Тест завершен'">
            <img src="@/assets/images/icons/clock4.svg" alt="Статус:Тест завершен"/>
          </div>
        </div>
      </div>
    </Popper>
    <div class="report_cell">
      <ReportsRender v-if="params.value?.finished_tests && params.value?.finished_tests.length"
                     :finished_tests="params.value.finished_tests"/>
    </div>
  </div>
  <div v-else>
    {{ params.value?.status_value || params.value }}
  </div>
</template>

<script>
import ReportsRender from "@/components/Tables/CellRenderers/ReportRenderer.vue";

export default {
  name: "status-renderer",
  components: {ReportsRender},
};
</script>

<style lang="scss" scoped>
.status-icon {
  width: 22px;
  height: 22px;
  margin: auto;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.status-popper {
  display: block;
  font-size: 14px;
  cursor: help;
}

.status-cells {
  width: 100px;
  margin: auto;
  display: flex;
  column-gap: 5px;
  align-items: center;
}
</style>


