export const getTestStatus = (staff) => {
    if (staff.test_passings) {
        if (staff.test_passings.length == 0) {
            return "Тест не выдан";
        } else {
            const status = staff.test_passings.status;
            if (
                status === "questionnaire" ||
                status === "instruction" ||
                status === "new"
            ) {
                return "Тест не пройден";
            } else if (status === "in_progress") {
                return "В процессе прохождения";
            } else {
                return "Тест завершен";
            }
        }
    }
};
