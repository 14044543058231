<template>
  <button
    @click="
      store.commit('ui/showCoursePopup', {
        show: true,
        courses: params.value[0],
        trajectories: params.value[1]
      })
    "
    class="course-btn"
    v-if="params.value[0]?.length || params.value[1]?.length"
  >
    <i class="fal fa-clipboard-check"></i>
  </button>
  <button
    @click="showNotification('Нет назначенных курсов или тренажеров')"
    class="course-btn blocked"
    v-else
  >
    <i class="fal fa-clipboard"></i>
  </button>
</template>

<script>
import { notify } from "@kyvg/vue3-notification";
import { useStore } from "vuex";
export default {
  name: "class-renderer",
  setup() {
    const store = useStore();
    const showNotification = (text, type = "warning") => {
      notify({
        title: text,
        type,
      });
    };

    return { store, showNotification };
  },
};
</script>

<style lang="scss" scoped>
.course-btn {
  width: 50px;
  height: 45px;
  margin: auto;
  display: block;
  i {
    font-size: 24px !important;
    color: var(--main-color) !important;
  }

  &:not(.blocked):hover i {
    color: var(--light-color) !important;
  }

  &.blocked {
    cursor: auto;
    i {
      color: grey !important;
    }
  }
}
</style>
